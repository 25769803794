import * as React from "react";
import { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import Layout from "../components/layout/Layout";
import ContactForm from "../components/contact/ContactForm";
import { fadeLeft, fadeRight } from "../components/_animationVariants";
import { imgAlt } from "../utilities";
import t from "../i18n";
import HeadMeta from "../components/layout/HeadMeta";


interface IContact {

}

const Contact: FC<IContact> = () => {
    const query = useStaticQuery( graphql`{
        contact: file(relativePath: {eq: "contact-section.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
    ` )
    return (
        <Layout>
            <HeadMeta title={ t( "Contact" ) }/>
            <section className={ "flex--center overflow--hidden h--v100 relative decoration-x" }>
                <motion.div variants={ fadeLeft } className={ "intro__image col--6 h--v100 img--full-height none lg:block relative" }>
                    <GatsbyImage
                        className={ "h--v100" }
                        image={ query.contact.childImageSharp.gatsbyImageData }
                        imgStyle={ { objectFit: "cover" } }
                        alt={ imgAlt( query.contact.relativePath ) }
                    />
                </motion.div>
                <motion.div className={ "lg:col--6 flex--center-middle w--100 h--100 px--xl" } variants={ fadeRight }>
                    <div className={ "w--100 max-w--700" }>
                        <h1 className={ "text--xl-1 pb--xl" }>{ t( "Contact" ) }</h1>
                        <ContactForm/>
                    </div>
                </motion.div>
            </section>
        </Layout>
    )
}

export default Contact
